<template>
    <h1>Edit details of allocation</h1>
    <div class="flex">
        <div class="w-2/6">Mandatory information</div>
        <div class="w-1/6 ml-8">Optional information</div>
    </div>
    <div class="flex">
        <div class="w-2/6">
            <div class="w-1/3">
                <h3>Fabric:</h3>
                <input v-model="fabricOrderd" placeholder="" readonly/>
            </div>
            <div class="w-1/3">
                <h3>Fabric (meters):</h3>
                <input v-model="stock" placeholder="" />
                <h3>Booking date:</h3>
                <input v-model="bookingDate" placeholder="" />
                <h3>CCD date:</h3>
                <input v-model="readyDate" placeholder="" />
                <h3>Status:</h3>
                <input type="checkbox" id="checkbox" v-model="statusBooking" />
                <label for="checkbox">{{ toogleText(statusBooking) }}</label>
                <h3>LC Status:</h3>
                <input type="checkbox" id="checkbox" v-model="lcStatus" />
                <label for="checkbox">{{ toogleText_lc(lcStatus) }}</label>
            </div>
        </div>
        <div class="w-1/4 bg-gray text-white rounded-md p-4 ml-8">
            <h3>Fabric Name + Code:</h3>
            <input class="text-black" v-model="fabricNameCode" placeholder="" />
            <h3>Color:</h3>
            <input class="text-black" v-model="color" placeholder="" />
            <h3>Supplier name:</h3>
            <input class="text-black" v-model="supplierName" placeholder="" />
        </div>
    </div>
    <div class="flex">
        <div class="w-full">
            <h3>Remarks:</h3>
            <textarea class="w-2/6 h-24" v-model="comment" placeholder="Comment"></textarea><br />

            <button class="appRegularButton bg-gray" @click="$router.push('/supplier')">Cancel</button>
            <button class="appRegularButton" @click="addBooking()">Send update</button>
        </div>
    </div>
</template>

<style>
input {
    color: #000000 !important;
}
</style>

<script>
import moment from 'moment'
import { useSettings } from '@/store/user'

export default {
    name: "EditBookingMills",
    setup() {
        const user = useSettings();

        return { user }
    },
    data() {
        return {
            'orderType': 'use',
            'p_id': this.user.editItem.p_id,
            'brand': this.user.editItem.brand,
            'styleNo': this.user.editItem.styleNo,
            'styleName': this.user.editItem.styleName,
            'fabricNameCode': this.user.editItem.fabricNameCode,
            'color': this.user.editItem.color,
            'millName': this.user.editItem.millName,
            'supplierName': this.user.editItem.supplierName,
            'companyId': this.user.editItem.companyId,
            'fabricOrderd': this.user.editItem.fabricOrderd,
            'bookingType': this.user.editItem.bookingType,
            'statusBooking': this.user.editItem.statusBooking,
            'statusProduction': this.user.editItem.statusProduction,
            'stock': this.user.editItem.stock,
            'cretedByEmail': this.user.editItem.cretedByEmail,
            'comment': this.user.editItem.comment,
            'recalcMeter': '1.00',
            'recalcYards': '2.20',
            'recalcPcs': '2.50',
            'readyDate': this.user.editItem.readyDate,
            'createdDate': this.user.editItem.createdDate,
            'bookingDate': this.user.editItem.bookingDate,
            'lcStatus': this.user.editItem.lcStatus,
            'shipmentDate': this.user.editItem.shipmentDate,
            'lastUpdated': moment(new Date()).format("YYYY-MM-DD HH:mm"),
        }
    },
    methods: {
        toogleText() {
            if (this.statusBooking == false) {
                return ' Ordered'
            } else {
                return ' Completed'
            }
        },
        toogleText_lc(){
            if (this.lcStatus == false) {
                return ' Awaiting'
            } else {
                return ' Completed'
            }
        },
        async addBooking() {
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    'p_id': this.p_id,
                    'orderType': this.orderType,
                    'brand': this.brand,
                    'styleNo': this.styleNo,
                    'styleName': this.styleName,
                    'fabricNameCode': this.fabricNameCode,
                    'color': this.color,
                    'millName': this.millName,
                    'supplierName': this.supplierName,
                    'companyId': this.companyId,
                    'fabricOrderd': this.fabricOrderd,
                    'bookingType': this.bookingType,
                    'statusBooking': this.statusBooking,
                    'statusProduction': this.statusProduction,
                    'stock': this.stock,
                    'cretedByEmail': this.cretedByEmail,
                    'comment': this.comment,
                    'recalcMeter': this.recalcMeter,
                    'recalcYards': this.recalcYards,
                    'recalcPcs': this.recalcPcs,
                    'readyDate': this.readyDate,
                    'createdDate': this.createdDate,
                    'bookingDate': this.bookingDate,
                    'lcStatus': this.lcStatus,
                    'shipmentDate': this.shipmentDate,
                    'lastUpdated': this.lastUpdated
                })
            };
            const response = await fetch(process.env.VUE_APP_BACKEND + "/data_supplier", requestOptions);
            // eslint-disable-next-line
            const data = await response.json();
            this.$router.push('/supplier');
        }
    }
}
</script>