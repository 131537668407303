<template>
    <div>
        <NewBookingSupplier />
    </div>
</template>

<script>
import NewBookingSupplier from '@/components/supplier/NewBooking.vue';

export default {
    components: {
        NewBookingSupplier,
    }
}
</script>