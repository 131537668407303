<template>
    <div>
        <EditBookingMills />
    </div>
</template>

<script>
import EditBookingMills from '@/components/supplier/EditBooking.vue';

export default {
    components: {
        EditBookingMills,
    }
}
</script>