export default {
    methods: {
        currency(value) {
            return new Intl.NumberFormat("da-DK",{ style: "currency", currency: "DKK" }).format(value);
        },
        numbers(value) {
            return new Intl.NumberFormat("da-DK",{ style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            if(date == 'Invalid Date') { 
                return 'Never'
            } else {
                return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
            }
        }
    },
  };