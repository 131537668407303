<template>
    <h1>Create company page</h1>
    <div>There are two seperate company's that can be created, unique id's are important and we prefix a Direct Mill with DM and Supplier with SU. Both of them ends with a number e.g DM3, DM4, SU3, SU4.<br/><br/>The unique id ensures that the data with the app in only visible to that single mill or supplier.</div>

    <h3>Company Id:</h3>
    <input class="text-black" v-model="companyId" placeholder="" />
    <h3>Name:</h3>
    <input class="text-black" v-model="name" placeholder="" />
    <h3>Nickname:</h3>
    <input class="text-black" v-model="nickName" placeholder="" />
    <br/><br/>
    <button class="appRegularButton" @click="this.$router.push('/profile')">Cancel</button>
    <button class="appRegularButton" @click="createCompany()">Add</button>
</template>

<script>
import { useSettings } from '@/store/user';

export default {
    setup() {
        const user = useSettings();

        return { user }
    },
    data() {
        return {
            name: '',
            companyId: '',
            nickName: ''
        }
    },
    methods: {
        async createCompany() {
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    'name': this.name,
                    'nickName': this.nickName,
                    'companyId': this.companyId,
                })
            };
            const response = await fetch(process.env.VUE_APP_BACKEND + "/createCompany", requestOptions);
            // eslint-disable-next-line
            const data = await response.json();
            this.$router.push('/profile');
        }
    }
}
</script>

<style></style>