import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import './assets/css/index.css'
import './assets/css/fonts.css'

import store from "@/store/store.js";
import router from "@/router";
import VueGtag from "vue-gtag";

// favicon
import { useFavicon } from '@vueuse/core'
const icon = useFavicon();
icon.value = './favicon.ico'

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueGtag, { 
  appName: process.env.VUE_APP_GTAG_NAME,
  pageTrackerScreenviewEnabled: true,
  config: { id: process.env.VUE_APP_GTAG }}, router)

router.isReady().then(() => {
  app.mount("#app");
});