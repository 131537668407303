<template>
  <div class="containerNew">
    <div class="text text-brands w-full mobile:text-xl desktop:text-3xl">
      DESIGN . INDKØB . RETAIL . SALG
    </div>
    <img class="image w-full" src="@/assets/img/image.jpg" />
    <div class="text text-header mobile:text-9xl">JOIN<br />THE<br />FUTURE!</div>
    <div class="text text-subheader text-white bold mobile:text-3xl">
      ONLY KARRIEREDAG 08.05.2024<br />KL 15.00 - 19.00
    </div>
  </div>
  <div class="bg-white text-center">
    <div class="m-4">
      <div class="text-black text-center font-bold mt-8">
        BRÆNDER DU FOR EN<br />INTERNATIONAL KARRIERE?
      </div>

      <div class="border border-pink mt-4 mb-4 mobile:ml-64 mobile:mr-64"></div>

      <div class="mb-4">
        ONLY er ét af Europas førende modebrands – og nu har du chancen for at opleve ONLY
        indefra!
      </div>

      <div class="mb-4">
        Vi afholder karrieredag på ONLY´s hovedkontor i Brande, hvor du kan møde
        dedikerede ONLY kolleger fra:
      </div>

      <div class="font-bold text-center text-pink">
        DESIGN . INDKØB <br />RETAIL . SALG
      </div>

      <div class="mt-4 mb-4">
        Du får mulighed for at gå i dybden med lige præcis det område, du brænder for, og
        dermed få en forståelse for, hvad det vil sige at starte en international karriere
        i ONLY. Hør samtidig mere om skræddersyede elevuddannelser inden for hvert område.
      </div>

      <div class="mb-4">
        Afslutningsvis inviterer vi på standing dinner og networking, hvor du får mulighed
        for at tale med dine måske kommende ONLY kolleger.
      </div>

      <div class="mb-4">
        Vi har et begrænset antal pladser, så hvis du er interesseret og vil sikre dig en
        plads, kan du tilmelde dig nedenfor. Deadline er 1. maj 2024.
      </div>
    </div>
  </div>

  <div
    v-if="closed"
    class="bg-gradient-to-r from-contact-dark via-contact-light to-contact-dark pb-4"
  >
    <h1 class="text-center text-white">LUKKET FOR TILMELDINGER!</h1>
    <div class="text-center text-white">
      Tak for din interesse i ONLY’s elev- og karrieredag den 8. maj.<br /><br />
      Der er desværre ikke flere ledige pladser, men du kan læse mere om vores åbne
      stillinger på <a target="_blank" href="https://career.only.com">career.only.com</a>.
    </div>
  </div>

  <div
    v-if="!submitted && !closed"
    class="bg-gradient-to-r from-contact-dark via-contact-light to-contact-dark"
  >
    <h1 class="text-center text-white">TILMELD DIG HER:</h1>
    <form class="flex flex-col items-center justify-center">
      <input
        v-model="userData.name"
        type="text"
        placeholder="Navn"
        class="p-2 m-2 border border-gray rounded shadow-md"
      />
      <input
        v-model="userData.email"
        type="email"
        placeholder="Email"
        class="p-2 m-2 border border-gray rounded shadow-md"
      />
      <input
        v-model="userData.phone"
        type="tel"
        placeholder="Mobil nummer"
        class="p-2 m-2 border border-gray rounded shadow-md"
      />
      <select
        v-model="userData.choiceA"
        class="p-2 m-2 border border-gray rounded shadow-md"
      >
        <option value="" disabled selected>Vælg 1. interesseområde</option>
        <option
          v-for="choice in filteredChoicesA"
          :key="choice.value"
          :value="choice.name"
        >
          {{ choice.name }}
        </option>
      </select>
      <select
        v-model="userData.choiceB"
        class="p-2 m-2 border border-gray rounded shadow-md"
      >
        <option value="" disabled selected>Vælg 2. interesseområde</option>
        <option
          v-for="choice in filteredChoicesB"
          :key="choice.value"
          :value="choice.name"
        >
          {{ choice.name }}
        </option>
      </select>
      <button
        @click.prevent="submitForm"
        class="p-2 m-2 mb-8 bg-green text-white rounded shadow-md"
      >
        Send tilmelding >>
      </button>
    </form>
  </div>
  <div v-if="submitted && !closed">
    <div
      class="text-center text-white bg-gradient-to-r from-contact-dark via-contact-light to-contact-dark mt-4 mb-4 p-4 rounded"
    >
      Tak for din tilmelding!<br /><br />Vi glæder os til at se dig på dagen, du vil høre
      fra os snarest.
    </div>
  </div>

  <div class="bg-white text-center mb-4">
    <div class="mt-4">
      Har du spørgsmål, kan du skrive til <br />Anne Kallesø Hansen på
      <a href="mailto:anne.kallesoe@bestseller.com?subject=Karrierredag i ONLY"
        >anne.kallesoe@bestseller.com</a
      >.
    </div>
  </div>

  <div class="m-4 border border-pink"></div>

  <div class="flex bg-white text-white items-center justify-center h-16">
    <div v-for="logo in logos" :key="logo" class="">
      <img class="w-32" :src="logo" />
    </div>
  </div>
</template>

<style></style>

<script>
import update from "@/mixins/update";

import { useSettings } from "@/store/user";

export default {
  name: "App",
  setup() {
    const user = useSettings();
    return { user };
  },
  data() {
    return {
      submitted: false,
      closed: true,
      choicesA: [
        { name: "Design", value: "0" },
        { name: "Indkøb", value: "1" },
        { name: "Retail", value: "2" },
        { name: "Salg", value: "3" },
      ],
      choicesB: [
        { name: "Design", value: "0" },
        { name: "Indkøb", value: "1" },
        { name: "Retail", value: "2" },
        { name: "Salg", value: "3" },
      ],
      logos: ["https://storage.googleapis.com/only-bi-bd-cdn/logos/only.png"],
      choiceA: "",
      choiceB: "",
      userData: {
        name: "",
        email: "",
        phone: "",
        choiceA: "",
        choiceB: "",
      },
    };
  },
  mixins: [update],
  components: {},
  computed: {
    canSubmit() {
      return (
        this.userData.name &&
        this.validateEmail(this.userData.email) &&
        this.validatePhone(this.userData.phone) &&
        this.validateChoice(this.userData.choiceA) &&
        this.validateChoice(this.userData.choiceB)
      );
    },
    filteredChoicesA() {
      return this.choicesA.filter((choice) => choice.name !== this.userData.choiceB);
    },
    filteredChoicesB() {
      return this.choicesB.filter((choice) => choice.name !== this.userData.choiceA);
    },
  },
  methods: {
    validateEmail(email) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      }
      alert("Fejl i email adresse, prøv igen.");
      return false;
    },
    validatePhone(phone) {
      if (/^\d{8}$/.test(phone)) {
        return true;
      } else {
        alert("Der er en fejl i telefonnummeret, prøv igen.");
        return false;
      }
    },
    validateChoice(choice) {
      if (choice) {
        return true;
      } else {
        alert("Du mangler at vælge et eller flere interesseområder.");
        return false;
      }
    },
    submitForm() {
      if (!this.canSubmit) {
        alert("Der er en fejl i din formular, prøv igen eller kontakt os for hjælp.");
        return;
      } else {
        // registering the user in our database
        fetch(process.env.VUE_APP_BACKEND + "/add", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.userData),
        });

        this.submitted = true;
      }
    },
  },
};
</script>

<style>
.containerNew {
  position: relative;
  width: 100%; /* Set the width of the container */
}

.image {
  width: 100%; /* Make sure image fills the container */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Make sure it's treated as a block element */
}

.text {
  position: absolute; /* Position the text relative to the container */
  top: 55%; /* Position it at 50% from the top */
  left: 50%; /* Position it at 50% from the left */
  transform: translate(-50%, -50%); /* Center the text */
  text-align: center; /* Center align the text */
}

.text-header {
  font-size: 4rem; /* Large font size */
  font-weight: bold; /* Bold text */
  color: #ed6fa7; /* Text color */
}

.text-subheader {
  position: absolute; /* Position the text relative to the container */
  top: 90%; /* Position it at 50% from the top */
  left: 50%; /* Position it at 50% from the left */
  font-size: 0.8rem; /* Large font size */
  font-weight: bold; /* Bold text */
}

.text-brands {
  position: absolute; /* Position the text relative to the container */
  top: 5%; /* Position it at 50% from the top */
  left: 50%; /* Position it at 50% from the left */
  transform: translate(-50%, -50%); /* Center the text */
  font-size: 0.7rem; /* Large font size */
  font-weight: bold; /* Bold text */
}
</style>
