<template>
    <h1>{{tableHeader}}</h1>
    <h3>{{ tableSubHeader }}</h3>
    
    <button v-if="user.userCompany != 'ONL'" class="appRegularButton mt-4" @click="$router.push('/new-booking-supplier')">Create new allocation</button>
    <div class="table w-full mt-4 bg-white rounded-md">
        <div class="table-header-group">
            <div class="table-row text-black font-semibold">
                <div class="table-cell text-left">CCD Date</div>
                <div class="table-cell text-left">Fabric</div>
                <div class="table-cell text-left">Amount (meters)</div>
                <div class="table-cell text-left">Supplier Name</div>
                <div class="table-cell text-left">Status</div>
                <div class="table-cell text-left">LC</div>
                <div class="table-cell text-left">Comment</div>
                <div class="table-cell text-left">Last update</div>
                <div class="table-cell text-left"></div>
            </div>
        </div>
        <div class="table-row-group">
            <div class="table-row" v-for="(item,index) in user.supplierBookings" :key="item" :item="item" :class="(index % 2==0)?'bg-gray-table-even':''">
                <div class="table-cell">{{ item.readyDate }}</div>
                <div class="table-cell">{{ item.fabricOrderd }}</div>
                <div class="table-cell">{{ numbers(item.stock) }}</div>
                <div class="table-cell">{{ item.supplierName }}</div>
                <div class="table-cell">{{ item.statusBooking == 'false' ? 'Ordered' : 'Completed' }}</div>
                <div class="table-cell">{{ item.lcStatus }}</div>
                <div class="table-cell">{{ item.comment }}</div>
                <div class="table-cell">{{ item.lastUpdated }}</div>
                <div v-if="user.userCompany != 'ONL'" class="table-cell"><button v-on:click="openDetails(item)" class="appAuth0Button text-xs bg-gray">Edit</button></div>                
            </div>
        </div>
    </div>
</template>

<script>
import { useSettings } from '@/store/user';
import mixins from '@/components/mixins';

export default {
    name: "AllBookings",
    mixins: [mixins],
    components: {
    },
    setup() {
        const user = useSettings();
        user.getDataMills();
        user.getDataSuppliers();

        return { user }
    },
    props: [
        'tableHeader',
        'tableSubHeader'
    ],
    methods: {
      toogleYesNo: function(item){
        item.lcStatus = !item.lcStatus;
      },
      toogleComplete: function(item) {
        item.statusBooking = !item.statusBooking;
      },
      openDetails: function(item) {
        this.user.$patch({ editItem: item });
        this.$router.push('/edit-booking-supplier');
      }
    }
}
</script>

<style>
.table-cell {
    @apply p-2 align-middle;
}
</style>