<template>
    <div>
        <NewBookingMills />
    </div>
</template>

<script>
import NewBookingMills from '@/components/mills/NewBooking.vue';

export default {
    components: {
        NewBookingMills,
    }
}
</script>