
import OldBookings from "@/components/mills/OldBookings.vue";

export default {
  components: {
    OldBookings,
  },
  data() {
    return {
      tableHeader: "All old and completed fabric bookings",
      tableSubHeader: "",
    };
  },
};
