<template>
    <div>
        <FabricOverview />
    </div>
</template>

<script>
import FabricOverview from '@/components/mills/FabricOverview.vue';

export default {
    components: {
        FabricOverview,
    }
}
</script>