
import AllBookings from '@/components/mills/AllBookings.vue';

export default {
  components: {
    AllBookings
  },
  data() {
    return {
      tableHeader: "Full overview of all fabric bookings",
      tableSubHeader: ""
    }
  }
}
