<template>
  <h1>
    Add fabric to company: {{ user.editCompany.name }} ({{ user.editCompany.companyId }})
  </h1>
  <h3>Fabric available:</h3>
  <select class="p-2" v-model="fabric" placeholder="" required>
    <option v-for:="(f, index) in user.allFabric">{{ f.name }}</option>
  </select>
  <button class="ml-4 appRegularButton" @click="updateFabric(user.editCompany.companyId)">
    Add fabric
  </button>
  <h3>Add new non-existing fabric:</h3>
  <input class="p-2" v-model="newFabricName" placeholder="Fabric name" required />
  <button class="ml-4 appRegularButton" @click="addNewFabric()">Add fabric</button>
  <br />
  <button class="mt-4 appRegularButton" @click="this.$router.push('/edit-company')">
    Back
  </button>
</template>

<script>
import { useSettings } from "@/store/user";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const user = useSettings();
    user.getDataFabrics();

    const { getDataFabrics } = storeToRefs(user);

    return { user, getDataFabrics };
  },
  data() {
    return {
      fabric: "",
      newFabric: "",
    };
  },
  methods: {
    async updateFabric() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: this.fabric,
          companyId: this.user.editCompany.companyId,
        }),
      };
      const response = await fetch(
        process.env.VUE_APP_BACKEND + "/addFabric",
        requestOptions
      );
      // eslint-disable-next-line
      const data = await response.json();
      this.$router.push("/edit-company");
    },
    async addNewFabric() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: this.newFabricName,
          companyId: this.user.editCompany.companyId,
        }),
      };
      const response = await fetch(
        process.env.VUE_APP_BACKEND + "/addFabric",
        requestOptions
      );
      // eslint-disable-next-line
      const data = await response.json();
      this.$router.push("/edit-company");
    },
  },
};
</script>

<style></style>
