<template>
  <h1>Edit company {{ name }} ({{ companyId }})</h1>
  <div>Company ID: {{ companyId }}</div>
  <div>Contact Person: {{ contactPerson }}</div>
  <div>Email: {{ email }}</div>
  <div>Adress: {{ adress }}</div>
  <div>Phone: {{ phone }}</div>
  <br />
  <button class="appRegularButton" @click="this.$router.push('/profile')">Back</button>
  <!-- <button class="appRegularButton" @click="updateCompany()">Save changes</button> -->

  <h2 v-if="user.company == 'ONL'" class="border-b-1 border-gray-dark">
    Accessible fabrics for this company:
  </h2>
  <div v-if="user.company == 'ONL'" class="table w-full mt-4 bg-white rounded-md">
    <div class="table-header-group">
      <div class="table-row text-black font-semibold">
        <div class="table-cell text-left">Fabric name</div>
        <div class="table-cell text-left"></div>
      </div>
    </div>
    <div class="table-row-group">
      <div
        class="table-row"
        v-for="(b, index) in this.user.millAvailableFabric"
        :key="b"
        :class="index % 2 == 0 ? 'bg-gray-table-even' : ''"
      >
        <div class="table-cell">{{ b.name }}</div>
        <div class="table-cell text-xs">
          <button class="" @click="deleteFabric(b.p_id, index)">Delete</button>
        </div>
      </div>
    </div>
  </div>

  <button
    v-if="user.company == 'ONL'"
    class="appRegularButton"
    v-on:click="this.$router.push('/add-fabric')"
  >
    Add access to fabric
  </button>
</template>

<script>
import { useSettings } from "@/store/user";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const user = useSettings();
    user.getDataFabrics();

    const { getDataFabrics } = storeToRefs(user);

    return { user, getDataFabrics };
  },
  data() {
    return {
      name: this.user.editCompany.name,
      email: this.user.editCompany.email,
      companyId: this.user.editCompany.companyId,
      nickName: this.user.editCompany.nickName,
      phone: this.user.editCompany.phone,
      adress: this.user.editCompany.adress,
      contactPerson: this.user.editCompany.contactPerson,
      accessibleFabrics: this.user.availableFabric,
    };
  },
  methods: {
    async updateCompany() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: this.name,
          nickName: this.nickName,
          companyId: this.companyId,
        }),
      };
      const response = await fetch(
        process.env.VUE_APP_BACKEND + "/updateCompany",
        requestOptions
      );
      // eslint-disable-next-line
      const data = await response.json();
      this.user.$patch({ userCompany: "ONL" });
      this.$router.push("/profile");
    },
    async deleteFabric(p_id, index) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          p_id: p_id,
          name: this.name,
          companyId: this.companyId,
        }),
      };
      const response = await fetch(
        process.env.VUE_APP_BACKEND + "/deleteFabric",
        requestOptions
      );
      this.user.millAvailableFabric.splice(index, 1);
      // eslint-disable-next-line
      const data = await response.json();
    },
  },
};
</script>

<style></style>
