<template>
    <h1>Create user</h1>
    <h3>Name:</h3>
    <input class="text-black" v-model="name" placeholder="" required /><br />
    <h3>Email:</h3>
    <input class="text-black" v-model="email" placeholder="" required /><br />
    <h3>Password:</h3>
    <input class="text-black" v-model="password" placeholder="" type="password" required /><br />
    
    <h3>Company ID:</h3>
    <select v-model="fabrictracker_companyId" placeholder="" required>
        <option value="ONL">ONLY Employee</option>
        <option v-for:="company in user.onlUsers" :value="company.companyId">{{ company.nickName }} ({{ company.companyId }})</option>
    </select><br />

    <button class="appRegularButton" @click="this.$router.push('/profile')">Cancel</button>
    <button class="appRegularButton" @click="addUser()">Send</button>
</template>

<script>
import { useSettings } from '@/store/user';

export default {
    setup() {
        const user = useSettings();

        return { user }
    },
    data() {
        return {
            name: '',
            email: '',
            password: '',
            fabrictracker_companyId: '',
            fabrictracker_role: 'user',
            auth0db: 'Fabric-Tracker'            
        }
    },
    methods: {
        async addUser() {
            // Set special values if we are from ONLY
            if(this.fabrictracker_companyId == 'ONL') {
                this.fabrictracker_role = 'admin';
            } else {
                this.fabrictracker_role = 'user';
            }

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    'name': this.name,
                    'email': this.email,
                    'password': this.password,
                    "connection": 'Username-Password-Authentication',
                    'user_metadata': {
                        'fabrictracker_companyId': this.fabrictracker_companyId,
                        'fabrictracker_role': this.fabrictracker_role
                    }
                })
            };
            const response = await fetch("https://only-bi-bd-api.master.k8s.bestcorp.net/auth0/create-user", requestOptions);
            // eslint-disable-next-line
            const data = await response.json();
            this.$router.push('/profile');
        }
    }
}
</script>

<style></style>