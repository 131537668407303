<template>
  <div>
    <h3>Loading....</h3>
  </div>
</template>
<script>
import Auth from '@/components/auth/Auth';
export default {
  name: 'CallBack',
  mounted() {
    const auth = new Auth();

    this.$nextTick(() => {
      auth.handleAuthentication();
    });
  },
};
</script>