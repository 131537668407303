import { defineStore } from 'pinia'
import moment from 'moment';

// quick and dirty bomb
const debugMode = false;

export const useSettings = defineStore('useSettings', {
  state: () => {
    return {
      isAuthenticated: debugMode,
      backendApi: process.env.VUE_APP_BACKEND,
      backendApiTestDb: process.env.VUE_APP_BACKEND_TEST_DB,
      userEmail: null,
      userName: null,
      userAddress: null,
      userPhone: null,
      userImage: null,
      userRole: null,
      userCompany: null,
      company: null,
      millBookings: [],
      millBookingsOverdue: [],
      editItem: null,
      editFabricOrders: null,
      editUser: null,
      editCompany: null,
      millBookingTypes: [
        'meter',
        'yards'
      ],
      millAvailableFabric: [],
      supplierBookings: null,
      brands: [
        { name: 'ONLY' },
        { name: 'ONLY&SONS' }
      ],
      detailedFabric: [],
      detailedFabricProduction: [],
      availableFabric: [],
      allFabric: [],
      onlFabric: [],
      onlUsers: [],
      users: [],
      usersMyCompany: [],
      VMI: [],
      VMIdata: [],
      VMIdataTweeked: [],
      VMItotalStockQty: [],
    }
  },
  actions: {
    async deleteBooking(item) {
      const res = await fetch(this.backendApi + "/delete/" + item.p_id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(item)
      });

      const data = await res.json();

      return data;
    },
    async getDataMills() {
      const res = await fetch(this.backendApi + "/data_mills");
      const data = await res.json();

      this.millBookingsOverdue = [];
      this.millBookings = [];
      this.millBookingStatus = 0;

      data.resultset.forEach(i => {
        // check if we have any overdue orders
        if (this.userCompany == i.companyId && i.deleted == null && i.statusProduction == 'false' && moment(i.readyDate).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')) {
          this.millBookingsOverdue.push(i);
        }

        // add to our bookings if companyId is matching
        if (this.userCompany == i.companyId && i.deleted == null || this.userCompany == 'ONL' && i.deleted == null) {
          this.millBookings.push(i);
        }
      });

      this.millBookings = this.millBookings.sort((a, b) => b.readyDate - a.readyDate);
    },
    async getDataSuppliers() {
      const resSuppliers = await fetch(this.backendApi + "/data_suppliers");
      const dataSuppliers = await resSuppliers.json();

      this.supplierBookingsOverdue = [];
      this.supplierBookings = [];

      dataSuppliers.resultset.forEach(b => {
        // check if we have any overdue orders
        if (this.userCompany == b.companyId && moment(b.readyDate).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')) {
          this.supplierBookingsOverdue.push(b);
        }

        // add to our bookings if companyId is matching
        if (this.userCompany == b.companyId || this.userCompany == "ONL") {
          this.supplierBookings.push(b);
        }
      });
    },
    async getDataFabrics() {
      const resFabric = await fetch(this.backendApi + "/fabrics/");
      const dataFabric = await resFabric.json();

      this.millAvailableFabric = [];
      this.availableFabric = [];
      this.allFabric = [];

      dataFabric.resultset.forEach(a => {
        if (this.userCompany == a.companyId) {
          this.millAvailableFabric.push(a);
        }
      });

      if(this.editCompany) {
        if (this.editCompany.companyId != null) {
          dataFabric.resultset.forEach(b => {
            if (this.editCompany.companyId == b.companyId) {
              this.availableFabric.push(b);
            }
          });
        }
      }

      dataFabric.resultset.forEach(c => {
        this.allFabric.push(c);
      });

      // sort array allFabric and remove dublicates
      this.availableFabric = this.availableFabric.sort((a, b) => a.name.localeCompare(b.name));
      this.millAvailableFabric = this.millAvailableFabric.sort((a, b) => a.name.localeCompare(b.name));
      this.allFabric = this.allFabric.sort((a, b) => a.name.localeCompare(b.name));
      
      // remove dublicates from allFabric
      this.allFabric = this.allFabric.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.name === item.name
        ))
      );

    },
    async getDataDetailedFabrics(companyId) {
      const resDetailedFabric = await fetch(this.backendApi + "/fabrics/details/" + companyId);
      const dataDetailedFabric = await resDetailedFabric.json();

      this.detailedFabric = [];
      this.detailedFabricProduction = [];

      dataDetailedFabric.resultset.forEach(b => {
        if (this.userCompany == b.companyId || this.userCompany == 'ONL') {
          this.detailedFabric.push(b);

          if (b.freeFabric != b.freeFabricWithProduction) {
            this.detailedFabricProduction.push(b);
          }
        }
      });
    },
    async getDataOnlFabrics() {
      const resOnlFabric = await fetch(this.backendApi + "/report/fabric/total");
      const dataOnlFabric = await resOnlFabric.json();

      this.onlFabric = dataOnlFabric.resultset;
    },
    async getDataOnlUsers() {
      const resOnlUsers = await fetch(this.backendApi + "/users/");
      const dataOnlUsers = await resOnlUsers.json();

      if (this.company == null) { this.company = this.userCompany; }
      this.onlUsers = dataOnlUsers.resultset;
    },
    async getUsers() {
      this.users = [];
      this.usersMyCompany = [];

      const resUsers = await fetch("https://only-bi-bd-api.master.k8s.bestcorp.net/auth0/get-users");
      const dataUsers = await resUsers.json();

      if (this.company == null) { this.company = this.userCompany; }

      dataUsers.data.forEach(c => {
        if (c.user_metadata.fabrictracker_companyId == 'ONL') {
          this.users.push(c);
        }
      });

      dataUsers.data.forEach(d => {
        if (d.user_metadata.fabrictracker_companyId == this.userCompany) {
          this.usersMyCompany.push(d);
        }
      });
    },
    async getVMI() {
      this.VMI = [];
      
      const resVMI = await fetch(this.backendApiTestDb + "/vmi/" + this.userCompany + "/");
      const dataVMI = await resVMI.json();

      this.VMI = dataVMI;
    },
    async getVMIData(styleId) {
      this.VMIdata = [];
      this.VMIdataTweeked = [];
      
      const resVMIData = await fetch(this.backendApiTestDb + "/vmi/data/" + styleId);
      const dataVMIData = await resVMIData.json();

      dataVMIData.forEach(i => {
        const newData = {
          STYLE_NUMBER: i.STYLE_NUMBER,
          STYLE_NAME: i.STYLE_NAME,
          BRAND: i.BRAND,
          SUBBRAND: i.SUBBRAND,
          COLOUR_VARIANT: i.COLOUR_VARIANT,
          IMAGE: i.IMAGE,
          EAN_NUMBER: i.EAN_NUMBER,
          SIZE_NAME: i.SIZE_NAME,
          SIZE_SORTING: i.SIZE_SORTING,
          LENGTHS_NAME: i.LENGTHS_NAME,
          companyId: i.companyId,
          name: i.name,
          db_key: i.EAN_NUMBER + '_' + i.companyId
        };

        // this.VMIdata.push({ ...newData, STOCK_AMOUNT: 0 });
        this.VMIdata.push({ ...newData });
      });

      this.VMIdata.forEach(a => {
        this.insertVMIData(a);
      });

      const resVMIDataTweeked = await fetch(this.backendApi + "/vmi/data/" + styleId);
      const dataVMIDataTweeked = await resVMIDataTweeked.json();

      this.VMIdataTweeked = dataVMIDataTweeked.resultset;
    },
    async insertVMIData(item) {
      const resUpdateVMI = await fetch(this.backendApi+ "/vmi/insert", {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(item)
      });

      const dataUpdateVMI = await resUpdateVMI.json();
    },
    async updateVMIData(item) {
      const resUpdateVMI = await fetch(this.backendApi+ "/vmi/update", {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(item)
      });

      const dataUpdateVMI = await resUpdateVMI.json();
    },
    async totalStockQty() {
      this.VMItotalStockQty = [];

      const resVMItotalStockQty = await fetch(this.backendApi + "/vmi/total-stock-qty/" + this.userCompany);
      const dataVMItotalStockQty = await resVMItotalStockQty.json();

      this.VMItotalStockQty = dataVMItotalStockQty.resultset;
    }
  },
})