<template>
  <h1>{{ tableHeader }}</h1>
  <h3>{{ tableSubHeader }}</h3>

  <button
    v-if="user.userCompany != 'ONL'"
    class="appRegularButton mt-4"
    @click="$router.push('/new-booking-mill')"
  >
    Create new booking
  </button>
  <button
    v-if="user.userCompany != 'ONL'"
    class="appRegularButton mt-4"
    @click="$router.push('/old-booking-mill')"
  >
    View completed bookings
  </button>
  <div class="table w-full mt-4 bg-white rounded-md">
    <div class="table-header-group">
      <div class="table-row text-black font-semibold">
        <div class="table-cell text-left">Ready Date</div>
        <div class="table-cell text-left">Fabric</div>
        <div class="table-cell text-left">Amount (meters)</div>
        <div class="table-cell text-left">Currency</div>
        <div class="table-cell text-left">Price (meter)</div>
        <div class="table-cell text-left">Status</div>
        <div class="table-cell text-left">Comment</div>
        <div class="table-cell text-left">Last update</div>
        <div class="table-cell text-left"></div>
      </div>
    </div>
    <div class="table-row-group">
      <div
        class="table-row"
        v-for="(item, index) in user.millBookings"
        :key="item"
        :item="item"
        :class="index % 2 == 0 ? 'bg-gray-table-even' : ''"
        v-show="item.statusProduction == 'false'"
      >
        <div class="table-cell">{{ item.readyDate }}</div>
        <div class="table-cell">{{ item.fabricOrderd }}</div>
        <div class="table-cell">{{ numbers(item.stock) }}</div>
        <div class="table-cell">{{ item.currency }}</div>
        <div class="table-cell">{{ item.currencyAmount }}</div>
        <div class="table-cell">
          {{
            item.statusProduction == "false"
              ? item.readyDate < today
                ? "In stock"
                : "In production"
              : "Completed"
          }}
        </div>
        <!-- <div class="table-cell"><button v-on:click="item.statusBooking = !item.statusBooking" class="appAuth0Button text-xs" :class="(item.statusBooking == 'true')?'bg-red':'bg-green'">{{ item.statusBooking == 'true' ? 'Mark as in production' : 'Mark as in stock' }}</button></div> -->
        <div class="table-cell">{{ item.comment }}</div>
        <div class="table-cell">{{ item.lastUpdated }}</div>
        <div v-if="user.userCompany != 'ONL'" class="table-cell">
          <button
            v-if="user.userEmail == 'line.schau@bestseller.com'"
            v-on:click="deleteDetails(item, index)"
            class="appAuth0Button text-xs bg-gray ml-2"
          >
            Delete
          </button>
          <button v-on:click="openDetails(item)" class="appAuth0Button text-xs bg-gray">
            Edit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSettings } from "@/store/user";
import mixins from "@/components/mixins";
import moment from "moment";

export default {
  name: "AllBookings",
  mixins: [mixins],
  components: {},
  setup() {
    const user = useSettings();
    user.getDataMills();
    user.getDataSuppliers();

    return { user };
  },
  data() {
    return {
      today: moment().format("YYYY-MM-DD"),
    };
  },
  props: ["tableHeader", "tableSubHeader"],
  methods: {
    toogleComplete: function (item) {
      item.statusBooking = !item.statusBooking;
    },
    openDetails: function (item) {
      this.user.$patch({ editItem: item });
      this.$router.push("/edit-booking-mill");
    },
    deleteDetails: function (item, index) {
      this.user.$patch({ editItem: item });
      this.user.millBookings.splice(index, 1);
      this.user.deleteBooking(item);
      alert("Order was deleted");
    },
  },
};
</script>

<style>
.table-cell {
  @apply p-2 align-middle;
}
</style>
