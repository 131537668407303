<template>
  <div class="flex">
    <div class="w-1/6">
      <h1 id="page-title" class="content__title"></h1>
      <img :src="user.userImage" alt="Profile" class="w-40 p-2 mt-2 rounded-full" />
    </div>
    <div class="w-2/6">
      <h1 id="page-title" class="content__title">User Profile</h1>
      <div class="profile__headline">{{ user.userName }} ({{ user.userRole }})</div>
      <div>Company: {{ user.userCompany }}</div>
      <div>Address: {{ user.userAddress }}</div>
      <div>Email: {{ user.userEmail }}</div>
      <div>Phone: {{ user.userPhone }}</div>
    </div>
    <div class="w-3/6 text-right">
      <div v-if="user.company == 'ONL'">
        <h3>Impersonate mill / supplier</h3>
        <select v-model="companyId" placeholder="" required class="p-1">
          <option disabled value="">Please select one</option>
          <option value="ONL">Reset</option>
          <option v-for:="u in user.onlUsers" :value="u.companyId">
            {{ u.companyId }} - {{ u.nickName }}
          </option>
        </select>
        <div class="hidden">
          Do not delete (shit will break and bad things will happen)
          {{ Impersonate(companyId) }}
        </div>
      </div>
    </div>
  </div>

  <!-- list all companies -->
  <h2 v-if="user.company == 'ONL'" class="border-b-1 border-gray-dark">
    List of all Companies in our app
  </h2>
  <div v-if="user.company == 'ONL'" class="table w-full mt-4 bg-white rounded-md">
    <div class="table-header-group">
      <div class="table-row text-black font-semibold">
        <div class="table-cell text-left">CompanyId</div>
        <div class="table-cell text-left">Name</div>
        <div class="table-cell text-left">Nickname</div>
        <div class="table-cell text-left"></div>
      </div>
    </div>
    <div class="table-row-group">
      <div
        class="table-row"
        v-for="(b, index) in user.onlUsers"
        :key="b.email"
        :class="index % 2 == 0 ? 'bg-gray-table-even' : ''"
      >
        <div class="table-cell">{{ b.companyId }}</div>
        <div class="table-cell">{{ b.name }}</div>
        <div class="table-cell">{{ b.nickName }}</div>
        <div class="table-cell text-xs">
          <button class="" @click="openDetailsCompany(b)">Edit</button>
        </div>
      </div>
    </div>
  </div>

  <button
    v-if="user.company == 'ONL'"
    class="appRegularButton"
    v-on:click="this.$router.push('/create-company')"
  >
    Add a new mill or supplier
  </button>

  <!-- list all users -->
  <h2 v-if="user.company == 'ONL'" class="border-b-1 border-gray-dark">
    List of all users for this app
  </h2>
  <div v-if="user.company == 'ONL'" class="table w-full mt-4 bg-white rounded-md">
    <div class="table-header-group">
      <div class="table-row text-black font-semibold">
        <div class="table-cell text-left">Name</div>
        <div class="table-cell text-left">Email</div>
        <div class="table-cell text-left">Last login</div>
        <div class="table-cell text-left"></div>
      </div>
    </div>
    <div class="table-row-group">
      <div
        class="table-row"
        v-for="(a, index) in user.users"
        :key="a.email"
        :class="index % 2 == 0 ? 'bg-gray-table-even' : ''"
      >
        <div class="table-cell">
          {{ a.name }} ({{ a.user_metadata.fabrictracker_companyId }})
        </div>
        <div class="table-cell">{{ a.email }}</div>
        <div class="table-cell">{{ formatDate(a.last_login) }}</div>
        <div class="table-cell text-xs">
          <button class="" @click="openDetailsUser(a)">Edit</button>
        </div>
      </div>
    </div>
  </div>
  <button
    v-if="user.company == 'ONL'"
    class="appRegularButton"
    v-on:click="this.$router.push('/create-user')"
  >
    Create new user
  </button>

  <!-- list all users only for my company -->
  <h2 v-if="user.userCompany != 'ONL'" class="border-b-1 border-gray-dark">
    List of all users
  </h2>
  <div v-if="user.userCompany != 'ONL'" class="table w-full mt-4 bg-white rounded-md">
    <div class="table-header-group">
      <div class="table-row text-black font-semibold">
        <div class="table-cell text-left">Name</div>
        <div class="table-cell text-left">Email</div>
        <div class="table-cell text-left">Last login</div>
        <div class="table-cell text-left"></div>
      </div>
    </div>
    <div class="table-row-group">
      <div
        class="table-row"
        v-for="(a, index) in user.usersMyCompany"
        :key="a.email"
        :class="index % 2 == 0 ? 'bg-gray-table-even' : ''"
      >
        <div class="table-cell">
          {{ a.name }} ({{ a.user_metadata.fabrictracker_companyId }})
        </div>
        <div class="table-cell">{{ a.email }}</div>
        <div class="table-cell">{{ formatDate(a.last_login) }}</div>
        <!-- <div class="table-cell text-xs"><button class="" @click="openDetailsUsers(a)">Edit</button></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { useSettings } from "@/store/user";
import { storeToRefs } from "pinia";
import mixins from "@/components/mixins";

export default {
  mixins: [mixins],
  setup() {
    const user = useSettings();
    user.getDataMills();
    user.getDataSuppliers();
    user.getDataFabrics(user.userCompany);
    user.getDataDetailedFabrics(user.userCompany);
    user.getDataOnlFabrics();
    user.getDataOnlUsers();
    user.getUsers();

    const {
      getUsers,
      getDataMills,
      getDataFabrics,
      getDataDetailedFabrics,
      getDataOnlFabrics,
      getDataOnlUsers,
    } = storeToRefs(user);

    return {
      user,
      getUsers,
      getDataMills,
      getDataFabrics,
      getDataDetailedFabrics,
      getDataOnlFabrics,
      getDataOnlUsers,
    };
  },
  data() {
    return {
      companyId: this.user.userCompany,
    };
  },
  methods: {
    Impersonate() {
      this.user.userCompany = this.companyId;

      return this.user.userCompany;
    },
    openDetailsUser: function (item) {
      this.user.$patch({ editUser: item });
      this.$router.push("/update-user");
    },
    openDetailsCompany: function (item) {
      this.user.$patch({ editCompany: item });
      this.$router.push("/edit-company");
    },
  },
};
</script>
