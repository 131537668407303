<template>
  <h1 class="mb-2">
    VMI - {{ this.user.editItem.STYLE_NUMBER }} - {{ this.user.editItem.STYLE_NAME }}
  </h1>

  <label class="text-black">Filter on EAN:</label>
  <input
    v-model="search"
    type="text"
    placeholder="Filter.."
    class="text-black ml-2 p-2"
  />

  <button @click="back()" class="appRegularButton ml-2">BACK</button>

  <div class="table w-full mt-4 bg-white rounded-md">
    <div class="table-header-group">
      <div class="table-row text-black font-semibold">
        <div class="table-cell text-left">PREVIEW</div>
        <div class="table-cell text-left">EAN NUMBER</div>
        <div class="table-cell text-left">COLOUR VARIANT</div>
        <div class="table-cell text-left">SIZE</div>
        <div class="table-cell text-left">LENGTH</div>
        <div class="table-cell text-left">STOCK QTY.</div>
      </div>
    </div>
    <div class="table-row-group">
      <div
        class="table-row"
        v-for="(item, index) in transformedArray"
        :key="item"
        :item="item"
        :class="index % 2 == 0 ? 'bg-gray-table-even' : ''"
      >
        <div
          v-show="item.isBlankRow == true"
          class="table-cell h-1 bg-orange-light"
        ></div>
        <div
          v-show="item.isBlankRow == true"
          class="table-cell h-1 bg-orange-light"
        ></div>
        <div
          v-show="item.isBlankRow == true"
          class="table-cell h-1 bg-orange-light"
        ></div>
        <div
          v-show="item.isBlankRow == true"
          class="table-cell h-1 bg-orange-light"
        ></div>
        <div
          v-show="item.isBlankRow == true"
          class="table-cell h-1 bg-orange-light"
        ></div>
        <div
          v-show="item.isBlankRow == true"
          class="table-cell h-1 bg-orange-light"
        ></div>

        <div class="table-cell" v-show="item.isBlankRow != true">
          <img
            :src="item.IMAGE"
            alt="preview"
            :class="{
              'img-default-size': true,
              'img-enlarged-size': imageEnlarged,
            }"
            @mouseover="enlargeImage()"
            @mouseout="shrinkImage()"
            v-show="item.isBlankRow != true"
          />
        </div>
        <div class="table-cell" v-show="item.isBlankRow != true">
          {{ item.EAN_NUMBER }}
        </div>
        <div class="table-cell" v-show="item.isBlankRow != true">
          {{ item.COLOUR_VARIANT }}
        </div>
        <div class="table-cell" v-show="item.isBlankRow != true">
          {{ item.SIZE_NAME }}
        </div>
        <div class="table-cell" v-show="item.isBlankRow != true">
          {{ checkNullValues(item.LENGTHS_NAME) }}
        </div>
        <div class="table-cell" v-show="item.isBlankRow != true">
          <input
            class="p-2"
            :class="index % 2 == 0 ? '' : 'bg-gray-table-even'"
            type="text"
            v-model="item.STOCK_AMOUNT"
            @change="updateVMI(item)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSettings } from "@/store/user";
import mixins from "@/components/mixins";

export default {
  mixins: [mixins],
  components: {},
  created() {
    this.user.VMIdataTweeked = [];
  },
  setup() {
    const user = useSettings();
    user.getVMIData(user.editItem.STYLE_NUMBER);

    return { user };
  },
  methods: {
    openDetails: function (item) {
      this.user.$patch({ editItem: item });
      this.$router.push("/edit-booking-mill");
    },
    back: function () {
      this.$router.push("/vmi");
    },
    updateVMI: function (item) {
      this.user.updateVMIData(item);
    },
    enlargeImage() {
      this.imageEnlarged = true;
    },
    shrinkImage() {
      this.imageEnlarged = false;
    },
    checkNullValues(input) {
      if (input == "null" || input == null || input == undefined) {
        return "";
      } else {
        return input;
      }
    },
  },
  data() {
    return {
      search: "",
      imageEnlarged: false,
    };
  },
  computed: {
    filteredArrayData() {
      return this.user.VMIdataTweeked.filter((item) => {
        return (
          item.EAN_NUMBER.toString().toLowerCase().includes(this.search.toLowerCase()) ||
          item.COLOUR_VARIANT.toString()
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.LENGTHS_NAME.toString()
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.SIZE_NAME.toString().toLowerCase().includes(this.search.toLowerCase()) ||
          item.STOCK_AMOUNT.toString().toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    transformedArray() {
      const result = [];
      let prevColorVariant = null;

      for (const item of this.filteredArrayData) {
        // Check if the color variant has changed
        if (item.COLOUR_VARIANT !== prevColorVariant) {
          // Insert a blank row
          result.push({
            isBlankRow: true,
          });
        }

        // Push the current item
        result.push(item);

        // Update the previous color variant
        prevColorVariant = item.COLOUR_VARIANT;
      }

      return result;
    },
  },
};
</script>

<style>
.table-cell {
  @apply p-2 align-middle;
}

.img-default-size {
  height: 40px;
  transition: all 0.2s;
}

.img-enlarged-size {
  height: 400px;
  transition: all 0.2s;
}
</style>
