/* eslint-disable */
import auth0 from 'auth0-js';
import router from '@/router';
import { useSettings } from '@/store/user';

// small hack to make auth work on all environments
let previousPageUrl = document.location.host;
if(previousPageUrl == '') { previousPageUrl = document.location.host }

export default class Auth {

    auth0 = new auth0.WebAuth({
        domain: 'dev-b63pwlciq843pu2g.us.auth0.com', // e.g kabiyesi.auth0.com
        clientID: 'hUvMK0OYrhJVn2eS0P4wM2CeXWwuQN6a', // e.g i473732832832cfgajHYEUqiqwq
        redirectUri: 'http://' + previousPageUrl + '/callback', // e.g http://localhost:8080/callback
        audience: 'https://dev-b63pwlciq843pu2g.us.auth0.com/api/v2/', // e.g. https://meetupapi.com
        responseType: 'token id_token',
        scope: 'openid profile email picture'
    });

    constructor() {
        this.login = this.login.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.logout = this.logout.bind(this);
    }

    handleAuthentication() {
        const store = useSettings();
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult);
                
                // save everything to the Pinia store
                store.$patch({ 
                    isAuthenticated: true, 
                    userCompany: authResult.idTokenPayload.fabrictracker.companyId, 
                    userRole: authResult.idTokenPayload.fabrictracker.role,
                    userEmail: authResult.idTokenPayload.email,
                    userImage: authResult.idTokenPayload.picture,
                    userName: authResult.idTokenPayload.name,
                    userAddress: '',
                    userPhone: ''
                })
                
                router.replace('/overview');
            } else if (err) {
                router.replace('/');
            }
        })
    }

    setSession(authResult) {
        // Set the time that the access token will expire at
        const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);
        localStorage.removeItem('state');
        sessionStorage.setItem('state', 1);   
    }

    requireAuth(to, from, next) {
        if (!(new Auth).isAuthenticated()) {
            next({
                path: '/',
                query: { redirect: to.fullPath }
            });
        } else {
            next();
        }
    }


    login() {
        this.auth0.authorize();
    }

    logout() {
        const store = useSettings();
        // Clear access token and ID token from local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('state');
        sessionStorage.setItem('state', 0);   

        store.$patch({ isAuthenticated: false })
        // navigate to the landing page route
        router.replace('/');
    }

    isAuthenticated() {
        // Check whether the current time is past the access token's expiry time
        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return new Date().getTime() < expiresAt;
    }
}