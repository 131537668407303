<template>
    <h1>Edit user</h1>
    <h3>Name:</h3>
    <input class="text-black" v-model="name" placeholder="" readonly /><br />
    <h3>Email:</h3>
    <input class="text-black" v-model="email" placeholder="" readonly /><br />
    <h3>New password:</h3>
    <input class="text-black" v-model="password" placeholder="" type="password" required /><br />

    <button class="appRegularButton" @click="this.$router.push('/profile')">Cancel</button>
    <button class="appRegularButton" @click="addUser()">Send</button>
</template>

<script>
import { useSettings } from '@/store/user';

export default {
    setup() {
        const user = useSettings();

        return { user }
    },
    data() {
        return {
            name: this.user.editUser.name,
            email: this.user.editUser.email,
            password: this.user.editUser.password,
            user_id: this.user.editUser.user_id,
            fabrictracker_companyId: this.user.editUser.user_metadata.fabrictracker_companyId,
            fabrictracker_role: this.user.editUser.user_metadata.fabrictracker_role
        }
    },
    methods: {
        async addUser() {
            const requestOptions = {
                method: "PATCH",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    'password': this.password,
                    "connection": "Username-Password-Authentication",
                    'user_metadata': {
                        'fabrictracker_companyId': this.fabrictracker_companyId,
                        'fabrictracker_role': this.fabrictracker_role
                    }
                })
            };
            const response = await fetch("https://only-bi-bd-api.master.k8s.bestcorp.net/auth0/update-user/" + this.user_id, requestOptions);
            // eslint-disable-next-line
            const data = await response.json();
            this.$router.push('/profile');
        }
    }
}
</script>

<style></style>