import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomePage from "@/pages/home-page.vue"
import DashboardPage from "@/pages/dashboard-page.vue"

import NotFoundPage from "@/pages/not-found-page.vue"
import MillsPage from "@/pages/mills-page.vue"
import NewBookingMillsPage from "@/pages/new-booking-mill-page.vue"
import EditBookingMillsPage from "@/pages/edit-booking-mill-page.vue"
import OldBookingMillsPage from "@/pages/old-bookings-page.vue"
import FabricOverviewMillsPage from "@/pages/fabric-overview-mill-page.vue"

import SupplierPage from "@/pages/supplier-page.vue"
import NewBookingSupplierPage from "@/pages/new-booking-supplier-page.vue"
import EditBookingSupplierPage from "@/pages/edit-booking-supplier-page.vue"
import CallbackPage from "@/components/auth/CallBack.vue"
import ProfilePage from "@/pages/profile-page.vue"

import CreateUserPage from "@/pages/create-user-page.vue"
import UpdateUserPage from "@/pages/update-user-page.vue"
import CreateFabricPage from '@/pages/create-fabric-page.vue'
import CreateCompanyPage from '@/pages/create-company-page.vue'
import EditCompanyPage from '@/pages/edit-company-page.vue'

import AddFabricPage from '@/pages/add-fabric-page.vue'

import VmiPage from '@/pages/vmi-page.vue'
import VmiDataPage from '@/pages/vmi-data-page.vue' 

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/overview',
    name: 'overview',
    component: DashboardPage,
  },
  {
    path: '/mill',
    name: 'mill',
    component: MillsPage,
  },
  {
    path: '/edit-company',
    name: 'edit-company',
    component: EditCompanyPage,
  },
  {
    path: '/create-user',
    name: 'create-user',
    component: CreateUserPage,
  },
  {
    path: '/create-company',
    name: 'create-company',
    component: CreateCompanyPage,
  },
  {
    path: '/create-fabric',
    name: 'create-fabric',
    component: CreateFabricPage
  },
  {
    path: '/add-fabric',
    name: 'add-fabric',
    component: AddFabricPage
  },
  {
    path: '/update-user',
    name: 'update-user',
    component: UpdateUserPage,
  },
  {
    path: '/new-booking-mill',
    name: 'new-booking-mill',
    component: NewBookingMillsPage,
  },
  {
    path: '/edit-booking-mill',
    name: 'edit-booking-mill',
    component: EditBookingMillsPage,
  },
  {
    path: '/old-booking-mill',
    name: 'old-booking-mill',
    component: OldBookingMillsPage,
  },
  {
    path: '/fabric-overview-mill',
    name: 'fabric-overview-mill',
    component: FabricOverviewMillsPage,
  },
  {
    path: '/supplier',
    name: 'supplier',
    component: SupplierPage,
  },
  {
    path: '/new-booking-supplier',
    name: 'new-booking-supplier',
    component: NewBookingSupplierPage,
  },
  {
    path: '/edit-booking-supplier',
    name: 'edit-booking-supplier',
    component: EditBookingSupplierPage,
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfilePage,
  },
  {
    path: "/callback",
    name: "callback",
    component: CallbackPage,
  },
  {
    path: "/:catchAll(.*)",
    name: "Not Found",
    component: NotFoundPage,
  },
  {
    path: '/vmi',
    name: 'vmi',
    component: VmiPage,
  },
  {
    path: '/vmi-data',
    name: 'vmi-data',
    component: VmiDataPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router