
import AllBookings from '@/components/supplier/AllBookings.vue';

export default {
components: {
  AllBookings
},
data() {
  return {
    tableHeader: "Full overview of all supplier allocations",
    tableSubHeader: ""
  }
}
}
